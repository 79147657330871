

(function() {
  'use strict';

  document.addEventListener('DOMContentLoaded', function() {
    const searchs = document.querySelectorAll('.js-search');
    let hasScript = false;

    // Functions
    const appendScript = function (key) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://maps.googleapis.com/maps/api/js?key=' + key + '&libraries=places&callback=initAutocomplete';
      script.addEventListener('load', () => {
        initAutocomplete;
      }, { once: true });
      document.body.appendChild(script);
    }

    if (searchs.length > 0) {

      searchs.forEach((element) => {

        // Variables
        const searchType = element.dataset.searchType;
        const searchInput = element.querySelector('.js-search-input');
        const latInput = element.querySelector('.js-search-lat');
        const lngInput = element.querySelector('.js-search-lng');
        let isCompleted = false;
        let hasDownBeenPressed = false;
        let autocomplete = null;


        // Functions
        const initAutocomplete = function () {
          var options = {};

          if(searchType == "address") {
            options = {
              componentRestrictions: { 'country': 'fr' }
            };
          }
          else {
            options = {
              types: ['(regions)'],
              componentRestrictions: { 'country': 'fr' }
            };
          }

          autocomplete = new google.maps.places.Autocomplete(searchInput, options);

          autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();

            if(place.geometry) {
              latInput.value = place.geometry.location.lat();
              lngInput.value = place.geometry.location.lng();
              isCompleted = true;
              hasDownBeenPressed = false;

              if(searchType == "address") {
                const components = place.address_components;
                var address = {};

                if(place.types[0] == 'point_of_interest' || place.types[0] == 'establishment' || place.types[0] == 'tourist_attraction') {
                  address['name'] = place.name;
                }

                components.forEach(component => {
                  if(component.types[0] == 'street_number') {
                    address['number'] = component.long_name;
                  }
                  else if(component.types[0] == 'route') {
                    address['street'] = component.long_name;
                  }
                  else if(component.types[0] == 'locality') {
                    address['city'] = component.long_name;
                  }
                  else if(component.types[0] == 'postal_code') {
                    address['postal_code'] = component.long_name;
                  }
                });


                // Fill values

                element.querySelector('.js-search-name').value = (address.name) ? address.name : "";
                element.querySelector('.js-search-city').value = (address.city) ? address.city : "";
                element.querySelector('.js-search-postal-code').value = (address.postal_code) ? address.postal_code : "";

                if(address.street) {
                  var full_street = "";
                  if(address.number) {
                    full_street = address.number + ", ";
                  }
                  full_street = full_street + address.street;
                  element.querySelector('.js-search-street').value = full_street;
                }
                else {
                  element.querySelector('.js-search-street').value = "";
                }

              }
            }
          });
        };


        // Listeners
        searchInput.addEventListener('keydown', (e) => {
          if (e.keyCode === 40) {
            hasDownBeenPressed = true;
          }
        });

        searchInput.addEventListener('keydown', (e) => {
          e.cancelBubble = true;

          if (e.keyCode === 13) {
            e.preventDefault();
          }

          if (e.keyCode === 13 || e.keyCode === 9) {
            if (!hasDownBeenPressed && !e.hasRanOnce) {
              const event = new Event('keydown');
              event.code = "ArrowDown";
              event.key = "ArrowDown";
              event.keyCode = 40;
              event.hasRanOnce = true;
              google.maps.event.trigger(e.target, 'keydown', event);
            }
          }
        });


        // Init

        window.initAutocomplete = initAutocomplete;

        if(!hasScript) {
          appendScript(searchInput.dataset.searchKey);
          hasScript = true;
        }

      });
    }
  });
})();
