/**
** JAVASCRIPTS
** Name: Alert
********************************************************************************/

(function() {
  'use strict';


  document.addEventListener("DOMContentLoaded", function() {


    if(document.querySelector('.js-alert-overlay') && document.querySelectorAll('.js-alert-open').length > 0) {


      // Variables

      const $html = document.querySelector('html');
      const $overlay = document.querySelector('.js-alert-overlay');
      const $openers = document.querySelectorAll('.js-alert-open');
      const $closers = document.querySelectorAll('.js-alert-close');
      const $form    = $overlay.querySelector('.js-alert-form');
      const $email   = $overlay.querySelector('.js-alert-email');
      const action   = $form.getAttribute('action');


      // Open

      $openers.forEach(($open)=>{
        $open.addEventListener('click', (event)=>{
          event.preventDefault();
          $html.classList.add('overlay-active');
          $overlay.classList.add('is-active');
          $email.focus();
        });
      });


      // Close

      $closers.forEach(($close)=>{
        $close.addEventListener('click', (event)=>{
          event.preventDefault();
          $html.classList.remove('overlay-active');
          $overlay.classList.remove('is-active');
        });
      });


      // Submit

      $form.addEventListener('submit', (event)=>{
        event.preventDefault();

        let form = event.target;

        $form.querySelectorAll('.js-form-control').forEach(($control) => {
          $control.classList.remove('c-form__control--error');
        });

        $form.querySelectorAll('.js-form-feedback').forEach(($feedback) => {
          $feedback.classList.add('is-hidden');
        });

        fetch(form.action, { method: form.method, body: new FormData(form) })
          .then(response => response.json())
          .then((json) => {
            if(json.created) {
              $overlay.querySelector('.js-alert-section[data-step="1"]').classList.add('is-hidden');
              $overlay.querySelector('.js-alert-section[data-step="2"]').classList.remove('is-hidden');
            }
            else {
              Object.keys(json.errors).forEach(key => {
                const $field = $form.querySelector('.js-form-field[data-id="' + key + '"]');
                const $control = $field.querySelector('.js-form-control');
                const $feedback = $field.querySelector('.js-form-feedback');
                const $text = $field.querySelector('.js-form-text');

                $control.classList.add('c-form__control--error');
                $feedback.classList.remove('is-hidden');
                $text.innerHTML = json.errors[key];
              });
            }
          });

        return false;
      });


    }

  });
})();
