/**
** JAVASCRIPTS
** Name: Editor
********************************************************************************/

import { Editor } from '@tiptap/core'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'

(function() {
  'use strict';


  if(document.querySelectorAll('.js-editor').length > 0) {


    /**
    ** Variables
    ****************************************/

    // const $html   = document.documentElement;
    const $editors = document.querySelectorAll('.js-editor');


    /**
    ** Events
    ****************************************/


    // Toggle

    $editors.forEach(($editor) => {
      const $label    = $editor.querySelector('.js-editor-label');
      const $textarea = $editor.querySelector('.js-editor-textarea');
      const $content  = $editor.querySelector('.js-editor-content');

      var editor = new Editor({
        element: $content,
        content: $textarea.value,
        extensions: [
          StarterKit,
          Link.configure({
            openOnClick: false,
          }),
        ],
      });


      // Update

      editor.on('update', ({ editor }) => {
        $textarea.value = editor.getHTML();

        if(editor.isActive('paragraph')) { $buttonText.classList.add('is-active'); } else { $buttonText.classList.remove('is-active'); }
        if(editor.isActive('heading', {level: 2})) { $buttonTitle.classList.add('is-active'); } else { $buttonTitle.classList.remove('is-active'); }
        if(editor.isActive('bold')) { $buttonBold.classList.add('is-active'); } else { $buttonBold.classList.remove('is-active'); }
        if(editor.isActive('italic')) { $buttonItalic.classList.add('is-active'); } else { $buttonItalic.classList.remove('is-active'); }
        if(editor.isActive('link')) {
          $buttonLink.classList.add('is-active');
          $buttonUnlink.classList.add('is-active');
        } else {
          $buttonLink.classList.remove('is-active');
          $buttonUnlink.classList.remove('is-active');
        }
      });

      editor.on('selectionUpdate', ({ editor }) => {
        if(editor.isActive('paragraph')) { $buttonText.classList.add('is-active'); } else { $buttonText.classList.remove('is-active'); }
        if(editor.isActive('heading', {level: 2})) { $buttonTitle.classList.add('is-active'); } else { $buttonTitle.classList.remove('is-active'); }
        if(editor.isActive('bold')) { $buttonBold.classList.add('is-active'); } else { $buttonBold.classList.remove('is-active'); }
        if(editor.isActive('italic')) { $buttonItalic.classList.add('is-active'); } else { $buttonItalic.classList.remove('is-active'); }
        if(editor.isActive('link')) {
          $buttonLink.classList.add('is-active');
          $buttonUnlink.classList.add('is-active');
        } else {
          $buttonLink.classList.remove('is-active');
          $buttonUnlink.classList.remove('is-active');
        }
      });

      $editor.addEventListener('keyup', () => {
        if(editor.isActive('paragraph')) { $buttonText.classList.add('is-active'); } else { $buttonText.classList.remove('is-active'); }
        if(editor.isActive('heading', {level: 2})) { $buttonTitle.classList.add('is-active'); } else { $buttonTitle.classList.remove('is-active'); }
        if(editor.isActive('bold')) { $buttonBold.classList.add('is-active'); } else { $buttonBold.classList.remove('is-active'); }
        if(editor.isActive('italic')) { $buttonItalic.classList.add('is-active'); } else { $buttonItalic.classList.remove('is-active'); }
        if(editor.isActive('link')) {
          $buttonLink.classList.add('is-active');
          $buttonUnlink.classList.add('is-active');
        } else {
          $buttonLink.classList.remove('is-active');
          $buttonUnlink.classList.remove('is-active');
        }
      });


      // Buttons

      const $buttonText   = $editor.querySelector('.js-editor-text');
      const $buttonTitle  = $editor.querySelector('.js-editor-title');
      const $buttonBold   = $editor.querySelector('.js-editor-bold');
      const $buttonItalic = $editor.querySelector('.js-editor-italic');
      const $buttonLink   = $editor.querySelector('.js-editor-link');
      const $buttonUnlink = $editor.querySelector('.js-editor-unlink');

      $buttonText.addEventListener('click', function(event) {
        event.preventDefault();
        editor.chain().focus().setParagraph().run();
        if(editor.isActive('paragraph')) { $buttonText.classList.add('is-active'); } else { $buttonText.classList.remove('is-active'); }
      });

      $buttonTitle.addEventListener('click', function(event) {
        event.preventDefault();
        editor.chain().focus().setHeading({level: 2}).run()
        if(editor.isActive('heading', {level: 2})) { $buttonTitle.classList.add('is-active'); } else { $buttonTitle.classList.remove('is-active'); }
      });

      $buttonBold.addEventListener('click', function(event) {
        event.preventDefault();
        editor.chain().focus().toggleBold().run();
        if(editor.isActive('bold')) { $buttonBold.classList.add('is-active'); } else { $buttonBold.classList.remove('is-active'); }
      });

      $buttonItalic.addEventListener('click', function(event) {
        event.preventDefault();
        editor.chain().focus().toggleItalic().run();
        if(editor.isActive('italic')) { $buttonItalic.classList.add('is-active'); } else { $buttonItalic.classList.remove('is-active'); }
      });

      $buttonLink.addEventListener('click', function(event) {
        event.preventDefault();

        const previousUrl = editor.getAttributes('link').href;
        const url = window.prompt('URL', previousUrl);

        // cancelled
        if (url === null) {
          return;
        }

        // empty
        if (url === '') {
          editor.chain().focus().extendMarkRange('link').unsetLink().run();
          $buttonLink.classList.remove('is-active');
          $buttonUnlink.classList.remove('is-active');
          return;
        }

        // update link
        editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
        $buttonLink.classList.add('is-active');
        $buttonUnlink.classList.add('is-active');
      });

      $buttonUnlink.addEventListener('click', function(event) {
        event.preventDefault();
        editor.chain().focus().unsetLink().run();
        $buttonUnlink.classList.remove('is-active');
      });

    });


  }

})();
