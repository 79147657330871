/**
** JAVASCRIPTS
** Name: Map
********************************************************************************/

import 'leaflet/dist/leaflet.js';


(function() {
  'use strict';

  if(document.querySelector('#map')) {


    /**
    ** Variables
    ****************************************/

    const $map = document.querySelector('#map');

    var map = L.map('map', {
      // dragging: !L.Browser.mobile,
      scrollWheelZoom: false,
      // touchZoom: true,
      zoomControl: false
    }).setView([0, 0], 2);

    var $events = document.querySelectorAll('.js-event');
    var $markers = [];
    var markers = [];

    var popupOptions = {
      autoPan: true,
      minWidth: 280,
      maxWidth: 320,
      className: "v-agenda__popup",
      closeButton: false
    };

    const yOffset = -140;


    /**
    ** Map styles
    ****************************************/

    L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      subdomains: 'abcd',
      maxZoom: 20
    }).addTo(map);

    new L.Control.Zoom({ position: 'topright' }).addTo(map);


    /**
    ** Group data by lat/lng
    ****************************************/

    var data = {};

    $events.forEach(function($event) {
      const $eventCard = $event.querySelector('.js-event-card');
      const $eventData = $event.querySelector('.js-event-data');

      if($eventData && $eventData.dataset && $eventData.dataset.lat && $eventData.dataset.lng && $eventData.dataset.date && $eventData.dataset.start) {
        const lat   = $eventData.dataset.lat;
        const lng   = $eventData.dataset.lng;
        const date  = $eventData.dataset.date;
        const start = $eventData.dataset.start;
        const id    = lat + "" + lng;

        if(id in data) {
          data[id].push({
            lat: lat,
            lng: lng,
            date: date,
            start: start,
            card: $eventCard
          });
        }
        else {
          data[id] = [{
            lat: lat,
            lng: lng,
            date: date,
            start: start,
            card: $eventCard
          }];
        }
      }
    });

    Object.keys(data).forEach(key => {
      data[key].sort(function(a, b) {
        return parseInt(a.start) - parseInt(b.start);
      });
    });


    /**
    ** Populate map
    ****************************************/

    Object.keys(data).forEach(key => {
      console.log(key, data[key]);

      const markerLat   = data[key][0].lat;
      const markerLng   = data[key][0].lng;
      var markerDate    = data[key][0].date;
      var $popupContent = document.createElement('div');

      if(data[key].length > 1) {
        markerDate = data[key].length + " événements " + markerDate.toLowerCase();
        data[key].forEach(event => {
          $popupContent.classList.add('v-agenda__multiple-cards');
          var $popupCard = document.createElement('div');
          $popupCard.classList.add('c-card', 'c-card--interactive', 'c-card--popup', 'c-card--light');
          $popupCard.innerHTML = event.card.innerHTML;
          $popupContent.innerHTML += $popupCard.outerHTML;
        });
      }
      else {
        $popupContent.classList.add('c-card', 'c-card--interactive', 'c-card--popup');
        $popupContent.innerHTML = data[key][0].card.innerHTML;
      }

      var markerIcon = L.divIcon({html: '<p class="v-agenda__text">' + markerDate + '</p>', className: 'v-agenda__marker'});
      var marker = L.marker([markerLat, markerLng], {icon: markerIcon});

      $markers.push(marker);
      markers.push([markerLat, markerLng]);

      marker.addTo(map);
      marker.bindPopup($popupContent, popupOptions);
    });


    /**
    ** Map fit bounds
    ****************************************/

    var bounds = new L.LatLngBounds(markers);

    map.fitBounds(bounds);


    /**
    ** Map interaction
    ****************************************/

    map.on('popupopen', function (e) {
      e.popup._source._icon.classList.add('is-active');
    });

    map.on('popupclose', function (e) {
      e.popup._source._icon.classList.remove('is-active');
    });


  }

})();
