export default class Toggler {
  constructor(element, target, autoClose = false) {
    this.element = element;
    this.target = target;
    this.autoClose = autoClose;
    this.expanded = null;
    this.mounted = false;

    this.handleClick = this.handleClick.bind(this);
    this.handleKeyup = this.handleKeyup.bind(this);
    this.handleTarget = this.handleTarget.bind(this);
    this.handleAutoClose = this.handleAutoClose.bind(this);
  }

  handleAutoClose(e) {
    const trigger = e.relatedTarget || e.target;
    if (!this.target.contains(trigger) && !this.element.contains(trigger)) {
      this.hide();
      document.removeEventListener('click', this.handleAutoClose);
    }
  }

  handleClick(e) {
    e.preventDefault();
    this.toggle();
  }

  handleKeyup(e) {
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      this.toggle();
    }
  }

  handleTarget(e) {
    if (e.detail.toggler.element !== this.element) {
      this.expanded = e.detail.toggler.expanded;
      this.element.setAttribute('aria-expanded', this.expanded);
    }
  }

  toggle() {
    if (this.expanded) {
      this.hide();
    } else {
      this.show();
    }
  }

  show() {
    this.element.setAttribute('aria-expanded', 'true');
    this.target.setAttribute('aria-hidden', 'false');
    this.expanded = true;
    this.target.focus();
    this.element.dispatchEvent(new CustomEvent('toggler:show', { detail: { toggler: this } }));

    if (this.autoClose) {
      document.addEventListener('click', this.handleAutoClose);
      document.addEventListener('focusin', this.handleAutoClose);
    }
  }

  hide() {
    if (this.autoClose) {
      document.removeEventListener('click', this.handleAutoClose);
      document.removeEventListener('focusin', this.handleAutoClose);
    }

    this.element.setAttribute('aria-expanded', 'false');
    this.target.setAttribute('aria-hidden', 'true');
    this.expanded = false;
  }

  mount() {
    this.element.setAttribute('aria-controls', this.target.id);
    this.element.setAttribute('aria-expanded', 'false');
    this.target.setAttribute('aria-hidden', 'true');
    this.target.setAttribute('tabindex', '-1');
    this.expanded = false;
    this.mounted = true;

    if (this.element.tagName !== 'BUTTON') {
      this.element.setAttribute('role', 'button');
      this.element.setAttribute('tabindex', 0);
      this.element.addEventListener('keyup', this.handleKeyup);
    }

    this.element.addEventListener('click', this.handleClick);
    this.element.dispatchEvent(new CustomEvent('toggler:mounted', { detail: { toggler: this } }));
  }

  unmount() {
    this.element.removeAttribute('aria-controls');
    this.element.removeAttribute('aria-expanded');
    this.target.removeAttribute('aria-hidden');
    this.target.removeAttribute('tabindex');
    this.expanded = null;
    this.mounted = false;

    if (this.element.tagName !== 'BUTTON') {
      this.element.removeAttribute('role');
      this.element.removeAttribute('tabindex');
      this.element.removeEventListener('keyup', this.handleKeyup);
    }

    this.element.removeEventListener('click', this.handleClick);
    this.element.dispatchEvent(new CustomEvent('toggler:unmounted', { detail: { toggler: this } }));
  }
}
