/**
** JAVASCRIPTS
** Name: Password
********************************************************************************/

(function() {
  'use strict';


  if(document.querySelectorAll('.js-password').length > 0) {


    /**
    ** Variables
    ****************************************/

    const $html = document.documentElement;
    const $passwords = document.querySelectorAll('.js-password');


    /**
    ** Events
    ****************************************/

    $passwords.forEach(($password) => {
      const $input  = $password.querySelector('.js-password-input');
      const $button = $password.querySelector('.js-password-button');


      // Toggle

      $button.addEventListener('click', ()=>{
        if($input.type == "password") {
          $input.type = "text";
        }
        else {
          $input.type = "password";
        }
      });

    });


  }

})();
