/**
** JAVASCRIPTS
** Name: Header
********************************************************************************/

(function() {
  'use strict';


  if(document.querySelectorAll('.js-menu').length > 0) {


    /**
    ** Variables
    ****************************************/

    const $html   = document.documentElement;
    const $toggle = document.querySelectorAll('.js-menu');


    /**
    ** Events
    ****************************************/


    // Toggle

    $toggle.forEach(($this) => {
      $this.addEventListener('click', ()=>{
        $html.classList.toggle('menu-active');
      });
    });


  }

})();
