import Toggler from '../utils/Toggler';

(function() {
  'use strict';

  document.addEventListener("DOMContentLoaded", function() {
    const dropdowns = document.querySelectorAll('.js-dropdown');

    dropdowns.forEach((element) => {


      // Variables

      const button = element.querySelector('.js-dropdown-button');
      const value = button.querySelector('.js-dropdown-value');
      const menu = element.querySelector('.js-dropdown-menu');
      const toggler = new Toggler(button, menu, true);


      // Functions

      const getLabels = function () {
        const labels = [];
        const inputs = menu.querySelectorAll('.js-dropdown-input');

        inputs.forEach((input) => {
          if (input.checked === true) {
            const label = menu.querySelector(`[for="${ input.id }"]`);
            labels.push(label.innerHTML);
          }
        });

        return labels;
      };

      const update = function () {
        const labels = getLabels();
        value.innerHTML = labels.join(', ');
      };


      // Listeners

      menu.addEventListener('change', update);



      // Init

      toggler.mount();
      update();
    });
  });
})();
