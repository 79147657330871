// import { throttle, debounce } from 'throttle-debounce';

import "./front/components/header";
import "./front/components/dropdown";
import "./front/components/search";
import "./front/components/alert";
import "./front/components/footer";
import "./front/components/map";
import "./front/components/menu";
import "./front/components/password";
import "./front/components/visio";
import "./front/components/editor";
import "./front/components/register";

if(document.querySelector('.js-print')) {
  document.querySelector('.js-print').addEventListener('click', ()=>{
    window.print();
  });
}
