/**
** JAVASCRIPTS
** Name: Header
********************************************************************************/

(function() {
  'use strict';


  if(document.querySelector('.js-header')) {


    /**
    ** Variables
    ****************************************/

    const $html   = document.documentElement;
    const $header = document.querySelector('.js-header');
    const $toggle = document.querySelectorAll('.js-header-toggle');

    var offsetTop     = (window.outerWidth >= 960) ? 10 : 1;
    var lastScrollPos = 0;
    var ticking       = false;


    /**
    ** Functions
    ****************************************/

    function setHeaderState(scrollPos, direction) {

      if(scrollPos >= offsetTop) {
        $html.classList.add('header-fixed');
      }
      else {
        $html.classList.remove('header-fixed');
      }

      if(direction > 0) {
        $html.classList.add('header-hidden');
      }
      else {
        $html.classList.remove('header-hidden');
      }
    }


    /**
    ** Events
    ****************************************/


    // Toggle

    $toggle.forEach(($this) => {
      $this.addEventListener('click', ()=>{
        $html.classList.toggle('menu-active');
      });
    });



    // Scroll

    window.addEventListener('scroll', ()=>{
      var scrollPos = window.pageYOffset || document.documentElement.scrollTop;
      var direction = scrollPos > lastScrollPos ? 1 : -1;

      if (!ticking) {
        window.requestAnimationFrame(function() {
          setHeaderState(scrollPos, direction);
          ticking = false;
        });
      }

      lastScrollPos = (scrollPos <= 0) ? 0 : scrollPos;
      ticking = true;
    });


    // Resize

    window.addEventListener('resize', ()=>{
      var windowWidth = window.outerWidth;

      if(windowWidth >= 960) {
        offsetTop = 10;
      }
      else {
        offsetTop = 1;
      }

    });


  }

})();
