/**
** JAVASCRIPTS
** Name: Visio
********************************************************************************/

(function() {
  'use strict';


  if(document.querySelector('.js-visio-toggle')) {


    /**
    ** Variables
    ****************************************/

    const $toggle  = document.querySelector('.js-visio-toggle');
    const $targets = document.querySelectorAll('.js-visio-target-field');


    /**
    ** Events
    ****************************************/

    if($targets.length > 0) {


      // Init

      if($toggle.checked) {
        $targets.forEach(($target) => {
          $target.classList.add('u-hidden');
        });
      }


      // Toggle

      $toggle.addEventListener('change', ()=>{
        if($toggle.checked) {
          $targets.forEach(($target) => {
            $target.classList.add('u-hidden');
          });
        }
        else {
          $targets.forEach(($target) => {
            $target.classList.remove('u-hidden');
          });
        }
      });


    }


  }

})();
