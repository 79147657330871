/**
** JAVASCRIPTS
** Name: Footer
********************************************************************************/

import Toggler from '../utils/Toggler';
import debounce from '../functions/debounce';
import Newsletter from '../utils/Newsletter';

(function() {
  'use strict';

  const footer = document.getElementById('footer');

  if(footer) {

    /**
    ** Variables
    ****************************************/

    const togglers = [];
    const newsletterElement = document.getElementById('newsletter');
    let tmp = footer.offsetWidth;
    let newsletter;


    /**
    ** Functions
    ****************************************/

    const handleDebouncedResize = () => {
      if (tmp !== footer.offsetWidth) {

        togglers.forEach((toggler) => {
          const isTogglable = window.getComputedStyle(toggler.element).getPropertyValue('position') === 'relative';

          if (toggler.mounted !== isTogglable) {
            if (isTogglable) {
              toggler.mount();
            } else {
              toggler.unmount();
            }
          }
        });

        tmp = footer.offsetWidth;
      }
    }


    /**
    ** Init
    ****************************************/

    if (newsletterElement) {
      newsletter = new Newsletter(newsletterElement);
      newsletter.mount();
    }

    footer.querySelectorAll('[data-footer-toggler]').forEach((element) => {
      const target = document.getElementById(element.dataset.footerToggler);
      const toggler = new Toggler(element, target);
      togglers.push(toggler);

      if (window.getComputedStyle(element).getPropertyValue('position') === 'relative') {
        toggler.mount();
      }
    });


    /**
    ** Events
    ****************************************/

    // Resize
    window.addEventListener('resize', debounce(handleDebouncedResize));

  }

})();
